export const getLatestItemInArray = <T>(arr: T[]): T | undefined => {
  if (arr.length === 0) return undefined;
  if (arr.length === 1) return arr[0];
  return arr[arr.length - 1];
};

export function paginateApiArray<T = any>(
  apiArr: T[],
  page: number,
  limit: number,
): T[] {
  const arr: T[] = [];

  for (let i = 0; i < (page - 1) * limit; i++) {
    arr.push(undefined);
  }

  arr.push(...apiArr);

  return arr;
}
